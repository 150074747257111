import axios from "~/plugins/axios";

export default {
  duedateList() {
    return axios.get("/ebsn/api/payment-due-date/list").then(data => {
      return data.data.data.paymentDueDates;
    });
  },

  duedateTransactions() {
    return axios.get("/ebsn/api/payment-due-date/transactions").then(data => {
      return data.data.data.orderTransaction;
    });
  },

  duedateListCreditGranted() {
    return axios
      .get("/ebsn/api/payment-due-date/list-credit-granted")
      .then(data => {
        return data.data.data.paymentCreditGrantedList;
      });
  },

  duedatePay(duedateIds, paymentTypeId, paymentAuthId, paymentData) {
    let params = {
      payment_due_date_id: duedateIds,
      payment_type_id: paymentTypeId
    };
    if (paymentData) {
      params.payment_data = encodeURI(JSON.stringify(paymentData));
    }
    return axios
      .post("/ebsn/api/payment-due-date/pay", null, {
        params: params
      })
      .then(data => {
        return data.data;
      });
  }
  // getPaymentsAuth(paymentTypeId) {
  //   return axios
  //     .get("/ebsn/api/payment-type/list-auth", {
  //       params: { payment_type_id: paymentTypeId }
  //     })
  //     .then(data => {
  //       return data.data.data.auths;
  //     });
  // },
  // deletePaymentAuth(paymentAuthId) {
  //   return axios
  //     .post("/ebsn/api/payment-type/delete-auth", null, {
  //       params: { payment_auth_id: paymentAuthId }
  //     })
  //     .then(data => {
  //       return data.data.data.auths;
  //     });
  // }
};
